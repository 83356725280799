<template>
    <el-select class="w-full" v-model="selectedValue" placeholder="請選擇單位" clearable popper-class="custom-dropdown" popper-append-to-body>
        <el-option v-for="item in flattenedUnits" :key="item.value" :label="item.rawName" :value="item.value" :disabled="item.disabled">
            <!-- 用 span 包含，依 depth 做 marginLeft -->
            <span :style="{ marginLeft: item.depth * 20 + 'px' }">
                {{ item.rawName }}
            </span>
        </el-option>
    </el-select>
</template>
<script>
export default {
    name: 'UnitTreeSelect',
    props: {
        // 假設最外層傳進來的是一個包含多個 root 單位的陣列
        units: {
            type: Array,
            default: () => [],
        },
        // v-model 雙向綁定的值 (選中的單位 id)
        value: {
            type: [Number, String, null],
            default: null,
        },
    },
    computed: {
        /**
         * 為了跟父層 (v-model) 雙向綁定
         */
        selectedValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val) // 注意 Vue 2.x 用 'input' 事件
            },
        },

        /**
         * 把多層樹狀資料扁平化，並且在資料中附上 depth、disabled 等資訊
         */
        flattenedUnits() {
            const result = []

            // 遞迴展開的函式
            const traverse = (unit, depth) => {
                result.push({
                    value: unit.id, // 你用來綁定 <el-option> :value 的資料
                    rawName: unit.name, // 用來顯示名稱
                    disabled: !unit.isUnitMember,
                    depth,
                })
                // 如果有下一層，繼續往下
                if (Array.isArray(unit.children) && unit.children.length) {
                    unit.children.forEach((child) => {
                        traverse(child, depth + 1)
                    })
                }
            }

            // 可能有多個 root
            this.units.forEach((rootUnit) => {
                traverse(rootUnit, 0)
            })

            return result
        },
    },
}
</script>

<template>
    <div class="flex flex-col p-4 border rounded shadow" style="border: #999 1px solid">
        <div class="h-full">
            <div class="flex justify-between">
                <!-- pid 姓名 -->
                <div class="whitespace-no-wrap">
                    <span class="font-semibold text-lg" :class="data.authorize == 1 ? 'cursor-pointer text-primary' : 'text-grey'" @click="goToMr()">{{ data.fullname }}, {{ $common.getGender(data.sex) }} {{ $common.getAge(data.birthday) }}</span>
                </div>
            </div>
            <!-- 帳號 手機 -->
            <div class="flex whitespace-no-wrap">
                <div class="flex items-center" :class="data.authorize == 1 ? '' : 'text-grey'">
                    <feather-icon class="mr-1" icon="UserIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span>{{ data.account }}</span>
                </div>
                <div class="ml-4 flex items-center text-primary" v-if="data.mobile">
                    <feather-icon class="mr-1" icon="PhoneIcon" svgClasses="w-4 h-4"></feather-icon>
                    <a :href="$common.getPhone(data.mobile)">{{ data.mobile }}</a>
                </div>
            </div>
            <!-- 病患未授權 -->
            <div class="whitespace-no-wrap text-danger" v-if="data.authorize == 0">
                <span class="truncate">{{ $t('membersMgmt.noAuth') }}</span>
            </div>
            <!-- 個人病史 -->
            <div class="whitespace-no-wrap" v-else>
                <div class="truncate" v-if="data.person_sick != ''">
                    <span v-if="lang == 'en'">
                        {{ data.person_sick_en }}
                    </span>
                    <span v-for="sick in data.person_sick" :key="sick.id" v-else>
                        {{ sick.name }}
                    </span>
                </div>
                <div v-else>
                    <span>{{ $t('membersMgmt.noDisease') }}</span>
                </div>
            </div>
            <!-- 單位 -->
            <div class="whitespace-no-wrap truncate" :class="data.authorize == 1 ? '' : 'text-grey'">
                <span v-for="(name, index2) in data.enterprise_name" :key="index2">
                    {{ name }}
                </span>
            </div>
        </div>
        <vs-divider />
        <div class="flex flex-wrap justify-evenly gap-x-4 gap-y-2">
            <div class="flex items-center" :class="data.authorize == 1 ? 'text-primary cursor-pointer hover:font-semibold' : 'text-grey'" :disabled="data.authorize == 0" @click="showCallPopup">
                <feather-icon icon="VideoIcon" class="mr-1" svgClasses="w-5 h-5" />
                <div>通話</div>
            </div>
            <div class="flex items-center text-primary cursor-pointer hover:font-semibold" @click="showChatPopup">
                <feather-icon icon="MessageCircleIcon" class="mr-1" svgClasses="w-5 h-5" />
                <div>{{ $t('membersMgmt.sendMsg') }}</div>
            </div>
            <vs-dropdown :class="data.authorize == 1 ? 'cursor-pointer hover:font-semibold' : ''" :disabled="data.authorize == 0" vs-custom-content vs-trigger-click>
                <div class="flex items-center" :class="data.authorize == 1 ? 'text-primary' : 'text-grey'">
                    <feather-icon icon="EyeIcon" class="mr-1" svgClasses="w-5 h-5" />
                    <span>會員資料</span>
                </div>
                <vs-dropdown-menu>
                    <ul style="min-width: 12rem">
                        <emr-tree-dropdown-item v-for="(node, index) in emrConfig" :pid="parseInt(data.pid)" :key="node.key" :node="node" />
                    </ul>
                </vs-dropdown-menu>
            </vs-dropdown>
            <vs-dropdown :class="data.authorize == 1 ? 'cursor-pointer hover:font-semibold' : ''" :disabled="data.authorize == 1 ? false : true" vs-custom-content vs-trigger-click>
                <div class="flex items-center" :class="data.authorize == 1 ? 'text-primary' : 'text-grey'">
                    <span>更多</span>
                    <feather-icon icon="MoreVerticalIcon" svg-classes="w-5 h-5" />
                </div>
                <vs-dropdown-menu>
                    <ul style="min-width: 12rem">
                        <!-- <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" :class="data.authorize == 1 ? 'text-primary' : 'text-grey disabled'" @click="goToMr()">
                            <span>檢視會員</span>
                        </li>
                        <vs-divider class="my-1" /> -->
                        <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" :class="data.authorize == 1 ? 'text-primary' : 'text-grey disabled'" @click.stop="showApptPopup(0)">
                            <span>{{ $t('crm.agent') }}</span>
                        </li>
                        <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" :class="data.authorize == 1 ? 'text-primary' : 'text-grey disabled'" @click.stop="showApptPopup(1)">
                            <span>{{ $t('crm.special') }}</span>
                        </li>
                        <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" :class="data.authorize == 1 ? 'text-primary' : 'text-grey disabled'" @click.stop="showApptPopup(2)">
                            <span>{{ $t('apptMgmt.consultation') }}</span>
                        </li>
                        <vs-divider class="my-1" />
                        <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" :class="data.authorize == 1 ? 'text-primary' : 'text-grey disabled'" @click="openNewCasePopup">
                            <span>{{ $t('membersMgmt.addCase') }}</span>
                        </li>
                        <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" :class="data.authorize == 1 ? 'text-primary' : 'text-grey disabled'" @click="showUploadPopup">
                            <span>{{ $t('membersMgmt.upload') }}</span>
                        </li>
                        <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" :class="data.authorize == 1 ? 'text-primary' : 'text-grey disabled'" @click="showQuestionPopup">
                            <span>發送問卷</span>
                        </li>
                        <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" :class="data.authorize == 1 ? 'text-primary' : 'text-grey disabled'" @click="openProductPopup" v-if="allow_shop">
                            <span>{{ $t('emr.appt.Recommend') }}</span>
                        </li>
                    </ul>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>
        <!-- 上傳醫療影像視窗 -->
        <upload-popup :uploadPopupActive="openUploadPopup" :spid="parseInt(pidUpload)" :spname="nameUpload" @closePopup="closeUploadPopup"></upload-popup>
    </div>
</template>

<script>
import uploadPopup from '@/components/uploadPopup'
import emrTreeDropdownItem from '@/components/Module/EmrTreeDropdownItem'
import { mapState } from 'vuex'
export default {
    components: {
        uploadPopup,
        emrTreeDropdownItem,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            openUploadPopup: false,
            pidUpload: 0,
            nameUpload: '',
        }
    },
    computed: {
        ...mapState(['unit_list', 'partner_id', 'did', 'windowWidth', 'allow_shop']),
        emrConfig() {
            let configStr = localStorage.getItem('his_config')
            if (!configStr) return []

            let config = JSON.parse(configStr)
            if (!config || !config.emr) return []

            let data = this.buildTree(config.emr)

            return data
        },
        lang() {
            return this.$i18n.locale
        },
    },
    methods: {
        // 開啟該病人的病歷
        goToMr(index = null, tabName = null) {
            // console.log('index', index)
            if (this.data.authorize != 1) return

            let routerPayload = {
                path: '/emr2/' + this.data.pid + '/0',
            }

            if (!index) {
                routerPayload.path += '?index=base'
                this.$router.push(routerPayload)
                return
            }

            routerPayload.path += '?index=' + index
            if (!tabName) {
                this.$router.push(routerPayload)
                return
            }

            routerPayload.path += '&tab=' + tabName
            this.$router.push(routerPayload)
        },
        // 開啟 掛號視窗
        showApptPopup(index) {
            const data = {
                apptMode: index,
                patient: {
                    pid: parseInt(this.data.pid),
                    name: this.data.fullname,
                },
            }
            this.$bus.$emit('appt', data)
        },
        // 開啟推薦商品視窗
        openProductPopup() {
            const payload = {
                sn_id: this.data.id,
                pid: this.data.pid,
                pname: this.data.fullname,
            }
            this.$bus.$emit('product', payload)
        },
        // 開啟 傳送訊息視窗
        showChatPopup() {
            const data = {
                smid: this.data.mid,
                sname: this.data.fullname,
            }
            this.$bus.$emit('chat', data)
        },
        // 開啟 通話視窗
        showCallPopup() {
            const payload = {
                title: '會員通話',
                pid: this.data.pid,
            }
            this.$bus.$emit('callList', payload)
        },
        // 開啟新增客服案件視窗
        openNewCasePopup() {
            const payload = {
                pid: this.data.pid,
                pname: this.data.fullname,
            }
            this.$bus.$emit('sn', payload)
        },
        // 開啟 醫療影像上傳視窗
        showUploadPopup() {
            if (this.data.authorize != 1) return
            this.pidUpload = this.data.pid
            this.nameUpload = this.data.fullname
            this.openUploadPopup = true
        },
        // 關閉 醫療影像上傳視窗
        closeUploadPopup() {
            this.openUploadPopup = false
        },
        // 開啟 發送問卷視窗
        showQuestionPopup() {
            const payload = {
                pid: this.data.pid,
                name: this.data.fullname,
            }
            this.$bus.$emit('question', payload)
        },
        // 建立emr目錄的那棵樹
        buildTree(data) {
            if (!data || typeof data !== 'object') return []

            let entries = Object.entries(data)
            entries = entries.filter(([k, v]) => v.show !== false)
            entries.sort(([, v1], [, v2]) => v1.order - v2.order)

            return entries.map(([key, val]) => {
                return {
                    key,
                    val,
                    children: val.contents ? this.buildTree(val.contents) : [],
                }
            })
        },
    },
}
</script>

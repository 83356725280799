<template>
    <li :style="{ paddingLeft: depth * 16 + 'px' }" :class="depth > 0 ? 'border border-solid border-grey border-l-1 border-r-0 border-t-0 border-b-0' : ''">
        <div class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click.stop="handleClick">
            <span>{{ getDisplayText(node.val) }}</span>
        </div>

        <ul v-if="node.children && node.children.length > 0">
            <emr-tree-dropdown-item v-for="(child, index) in node.children" :key="child.key" :node="child" :pid="pid" :depth="depth + 1" :pathKeys="[...pathKeys, node.key]" />
        </ul>
    </li>
</template>

<script>
export default {
    name: 'EmrTreeDropdownItem',
    props: {
        node: {
            type: Object,
            required: true,
        },
        pid: {
            type: Number,
            required: true,
        },
        depth: {
            type: Number,
            default: 0,
            required: false,
        },
        // 存放所有祖先 key (含上一層自己的 key)，用來組合路徑
        pathKeys: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    methods: {
        handleClick() {
            // 取得完整的路徑陣列，包括自己 (this.node.key)
            const fullPathKeys = [...this.pathKeys, this.node.key]
            // console.log('fullPathKeys:', fullPathKeys)

            // 依 fullPathKeys 的長度，組合出對應的參數
            // depth = 0 表示只有 1 層 => [index]
            // depth = 1 表示 2 層 => [index, tab]
            // depth = 2 表示 3 層 => [index, tab, tab2]
            const index = fullPathKeys[0] || null
            const tab = fullPathKeys[1] || null
            const tab2 = fullPathKeys[2] || null

            // 組合成 /emr2/:pid/0?index=xxx[&tab=xxx][&tab2=xxx]
            let routePath = `/emr2/${this.pid}/0`
            let query = {}
            if (index !== null) query.index = index
            if (tab !== null) query.tab = tab
            if (tab2 !== null) query.tab2 = tab2

            this.$router.push({ path: routePath, query })
        },

        getDisplayText(val) {
            if (val.i18n && this.$common) {
                return this.$common.getLang(val.i18n)
            }
            return val.name || this.node.key
        },
    },
}
</script>

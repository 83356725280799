<template>
    <div class="cd-body">
        <list :menu="menu" :total="total" :limit="limit" :isLoading="isLoading" :page.sync="page">
            <template v-slot:header-left>
                <div class="flex">
                    <div class="mr-4 flex items-center text-primary cursor-pointer hover:font-semibold" @click="$router.go(-1)">
                        <feather-icon class="mr-1" icon="ChevronLeftIcon" svgClasses="h-4 w-5"></feather-icon>
                        <span>{{ $t('membersMgmt.prev') }}</span>
                    </div>
                    <h3>{{ $t('membersMgmt.title') }}</h3>
                </div>
            </template>
            <template v-slot:header-right>
                <!-- 篩選 -->
                <div v-if="windowWidth <= 1024">
                    <vs-button class="cd-button-2w-icon rounded" type="flat" icon-pack="feather" icon="icon-filter" @click="showFilter">{{ $t('apptMgmt.filter') }}</vs-button>
                </div>
            </template>
            <template v-slot:sidebar>
                <!-- query -->
                <div class="mb-base">
                    <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-full input-rounded-full" v-model="query" />
                </div>
                <!-- unit -->
                <div class="mb-base" v-if="unit_list.length > 0">
                    <vs-radio class="w-full mb-3" v-model="unit" :vs-value="null">單位（不拘）</vs-radio>
                    <unit-tree-radio v-for="u in treeUnits" :key="u.id" :unit="u" :depth="1" v-model="unit" />
                </div>
                <!-- include children -->
                <!-- <div class="mb-base">
                    <vs-checkbox v-model="includeChildren" @change="selectFilter">含子單位</vs-checkbox>
                </div> -->
                <!-- tags -->
                <div class="mb-base" v-if="crmTagsList.length > 0">
                    <vs-radio class="w-full mb-3" v-model="tags" :vs-name="'tags'" :vs-value="null">{{ $t('membersMgmt.tags') }}</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-for="(item, index) in crmTagsList" :key="index" v-model="tags" :vs-name="'tags' + index" :vs-value="item">{{ item }}</vs-radio>
                </div>
                <!-- sicks -->
                <div class="mb-base" v-if="sickList.length > 0">
                    <vs-radio class="w-full mb-3" v-model="sicks.id" :vs-name="'sicks'" :vs-value="null">{{ $t('membersMgmt.disease') }}</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-for="(item, index) in sickList" :key="index" v-model="sicks.id" :vs-name="'sicks' + index" :vs-value="item.mh_item_id">{{ lang == 'en' ? item.ename : item.note }}</vs-radio>
                </div>
                <vs-divider v-if="windowWidth <= 600" />
                <!-- 篩選按鈕 -->
                <div class="mb-4 flex justify-around" v-if="windowWidth <= 600">
                    <vs-button type="border" @click="reset">{{ $t('popup.reset') }}</vs-button>
                    <vs-button class="cd-button-filled" @click="showFilter">{{ $t('popup.confirm') }}</vs-button>
                </div>
            </template>
            <template v-slot:content>
                <div class="mb-4 flex flex-wrap items-center gap-y-2 gap-x-4 text-lg" v-if="query || unit || tags || sicks.id">
                    <span>篩選條件：</span>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeQueryFilter" v-if="query">
                        {{ query }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeUnitFilter" v-if="unit">
                        {{ ename }}
                        <!-- <span class="text-sm">&nbsp;({{ includeChildren ? '含子單位' : '不含子單位' }})</span> -->
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeTagsFilter" v-if="tags">
                        {{ tags }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeSickFilter" v-if="sicks.id">
                        {{ getSickName(sicks.id) }}
                    </vs-chip>
                </div>
                <div class="mb-4 flex flex-wrap items-center justify-between gap-4 text-lg">
                    <div class="flex flex-wrap items-center gap-4">
                        <span>共 {{ total }} {{ $t('membersMgmt.dataLength') }}</span>
                    </div>
                    <div class="flex flex-wrap items-center gap-4">
                        <span class="whitespace-no-wrap text-primary cursor-pointer hover:font-semibold" @click="addMember" v-if="unit_list.length > 0">{{ $t('membersMgmt.addmember.btnname') }}</span>
                    </div>
                </div>
                <div class="mb-8 grid grid-cols-1 md:grid-cols-2 gap-4 items-stretch" v-if="membersList.length > 0">
                    <item :data="member" v-for="(member, index) in membersList" :key="index"></item>
                </div>
            </template>
        </list>
        <!-- 上傳醫療影像視窗 -->
        <upload-popup :uploadPopupActive="openUploadPopup" :spid="parseInt(pidUpload)" :spname="nameUpload" @closePopup="closeUploadPopup"></upload-popup>
        <!-- 推薦商品 -->
        <product-popup :productPopupActive="productPopupActive" @closePopup="productClose" :pid="recommandPid" :sn_id="recommsnid" :pname="recommpname"></product-popup>
    </div>
</template>
<style>
.con-vs-popup .vs-popup,
.err_dialog {
    z-index: 3000000 !important;
}
.con-vs-popup .err_dialog {
    z-index: 3000001 !important;
}
.custom-dropdown {
    z-index: 3000000 !important; /* Higher than vs-popup */
}
.custom-form .el-form-item__label {
    margin-bottom: 0px;
    line-height: 0.1;
}
.custom-form .el-form-item__content {
    margin-top: 0;
}
.custom-form .el-form-item {
    margin-bottom: 1rem;
}
</style>

<script>
import { getMemberFilter, getMemberList } from '@/api/user'
import uploadPopup from '@/components/uploadPopup'
import { debounce } from 'lodash'
import productPopup from '@/components/productPopup.vue'
import UnitTreeSelect from '@/components/Module/UnitTreeSelect.vue'
import UnitTreeRadio from '@/components/Module/UnitTreeRadio.vue'
import { mapState } from 'vuex'
import list from '@/components/Module/list.vue'
import item from '@/components/MemberMgmt/MemberItem.vue'

export default {
    components: {
        uploadPopup,
        productPopup,
        UnitTreeSelect,
        UnitTreeRadio,
        list,
        item,
    },
    data() {
        return {
            crmTagsList: [],
            sickList: [],
            unit: null,
            includeChildren: false,
            tags: null,
            sicks: {
                id: null,
            },
            query: '',
            limit: 30,
            page: 1,
            isLoading: false,
            membersList: [],
            total: 0,
            openUploadPopup: false,
            pidUpload: 0,
            nameUpload: '',
            goToMrToggle: false,
            menu: false,
            recommandPid: 0,
            recommsnid: 0,
            recommpname: '',
            productPopupActive: false,
        }
    },
    watch: {
        query: 'selectFilter',
        tags: 'selectFilter',
        'sicks.id': 'selectFilter',
        unit(v) {
            localStorage.setItem('selectedUnit', JSON.stringify(v))
            this.selectFilter()
        },
    },
    computed: {
        ...mapState(['unit_list', 'partner_id', 'did', 'windowWidth']),
        ename() {
            const data = _.filter(this.unit_list, { id: this.unit })
            return data.length > 0 ? data[0].name : ''
        },
        lang() {
            return this.$i18n.locale
        },
        emrConfig() {
            let data = JSON.parse(localStorage.getItem('his_config')).emr
            _.pull(data, _.find(data, { show: false }))
            let map = new Map()
            for (let k in data) {
                map.set(k, data[k])
            }
            let ArrayObj = Array.from(map)
            ArrayObj.sort((a, b) => {
                return a[1]['order'] - b[1]['order']
            })
            return ArrayObj
        },
        dicomConfig() {
            let data = JSON.parse(localStorage.getItem('his_config')).emr.dicomData.contents
            _.pull(data, _.find(data, { show: false }))
            let map = new Map()
            for (let k in data) {
                map.set(k, data[k])
            }
            let ArrayObj = Array.from(map)
            ArrayObj.sort((a, b) => {
                return a[1]['order'] - b[1]['order']
            })
            return ArrayObj
        },
        menuActive: {
            get() {
                if (this.windowWidth > 600) {
                    return true
                } else {
                    return this.menu
                }
            },
            set(val) {},
        },
        treeUnits() {
            return this.$common.buildUnitTree()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (localStorage.getItem('partnerid') == 31) next({ path: '/members' })
        else next()
    },
    created() {
        // 預設值
        const savedUnit = localStorage.getItem('selectedUnit')
        this.unit = savedUnit ? JSON.parse(savedUnit) : null

        this.getMemberFilter()
        this.createBus()
    },
    methods: {
        // 取會員
        getMemberList: debounce(function () {
            const eids = this.unit ? this.unit : this.getAllUnitId()
            if (!eids) {
                this.membersList = []
                this.total = 0
                return
            }
            this.isLoading = true
            let payload = {
                partner_id: this.partner_id,
                page: this.page,
                limit: this.limit,
                eids: eids,
                // includeChildren: this.includeChildren,
            }
            if (this.query) payload.query = this.query
            if (this.tags) payload.crm_tags = this.tags
            if (this.sicks.id) payload.disease = this.sicks
            getMemberList(payload).then((res) => {
                if (res.data.status === 'OK') {
                    this.membersList = res.data.data.items
                    this.total = res.data.data.total
                }
                this.isLoading = false
                window.scrollTo(0, 0)
            })
        }, 200),
        // 取公司和群組清單
        getMemberFilter() {
            var payload = {
                partner_id: this.partner_id,
            }
            getMemberFilter(payload).then((res) => {
                if (res.data.status === 'OK') {
                    this.crmTagsList = res.data.data.crm_tags
                    this.sickList = res.data.data.sick_list
                    this.getMemberList()
                }
            })
        },
        // 開啟該病人的病歷
        goToMr(data, index = null, tabName = null) {
            if (data.authorize != 1) return
            let patientJson = {
                name: data.name,
                pid: data.pid,
                account: data.account,
            }
            this.$store.commit('SET_ACTIVE_PATIENT_INFO', patientJson)

            var routerPayload = {
                path: '/emr2/' + data.pid + '/0',
            }
            if (!index) {
                routerPayload.path += '?index=base'
                this.$router.push(routerPayload)
                return
            }

            routerPayload.path += '?index=' + index
            if (!tabName) {
                this.$router.push(routerPayload)
                return
            }

            routerPayload.path += '&tab=' + tabName
            this.$router.push(routerPayload)
        },
        // 取所有單位 id，且串成 "id1,id2,id3" 的字串
        getAllUnitId() {
            return this.unit_list
                .filter((item) => item.isUnitMember > 0) // 過濾出符合條件的項目
                .map((item) => item.id) // 只取 id
                .join(',') // 用逗號串起來
        },
        // 開關前往病歷的toggle
        showGoToMrToggle(data) {
            if (data.authorize != 1) return
            this.goToMrToggle = !this.goToMrToggle
        },
        // 開啟 醫療影像上傳視窗
        showUploadPopup(data) {
            if (data.authorize != 1) return
            document.body.style.overflow = 'hidden'
            this.pidUpload = data.pid
            this.nameUpload = data.fullname
            this.openUploadPopup = true
        },
        // 關閉 醫療影像上傳視窗
        closeUploadPopup() {
            document.body.style.overflow = 'visible'
            this.openUploadPopup = false
        },
        // 開啟新增客服案件視窗
        showCasePopup(pid, name) {
            const payload = {
                pid: pid,
                pname: name,
            }
            this.$bus.$emit('sn', payload)
        },
        // 開啟選擇通話對象視窗
        openCallPopup(data) {
            const payload = {
                title: '會員通話',
                pid: data.pid,
                noContact: true,
                noEnterprise: true,
            }
            this.$bus.$emit('callList', payload)
        },
        //開啟推薦商品視窗
        openProductPopup(pid, id, name) {
            this.recommandPid = pid
            this.recommsnid = id
            this.recommpname = name
            document.body.style.overflow = 'hidden'
            this.productPopupActive = true
        },
        //關閉推薦商品視窗
        productClose() {
            document.body.style.overflow = 'visible'
            this.productPopupActive = false
        },
        // 手機版 開啟篩選介面
        showFilter() {
            this.menu = !this.menu
        },
        // 篩選回到預設
        reset() {
            this.query = ''
            this.unit = null
            this.tags = null
            this.sicks.id = null
            this.page = 1
            this.getMemberList()
        },
        // 取病名
        getSickName(id) {
            return this.lang == 'en' ? _.find(this.sickList, { mh_item_id: id }).ename : _.find(this.sickList, { mh_item_id: id }).note
        },
        // 新增會員
        addMember() {
            const payload = { default_eid: this.unit }
            this.$bus.$emit('member', payload)
        },
        selectFilter() {
            this.page = 1
            this.getMemberList()
        },
        closeQueryFilter() {
            this.query = ''
            this.getMemberList()
        },
        closeUnitFilter() {
            this.unit = null
            this.getMemberList()
        },
        closeTagsFilter() {
            this.tags = null
            this.getMemberList()
        },
        closeSickFilter() {
            this.sicks.id = null
            this.getMemberList()
        },
        createBus() {
            this.$bus.$on('changePage', (page) => {
                this.page = page
                this.getMemberList()
            })
            this.$bus.$on('refreshMemberList', () => {
                this.getMemberList()
            })
        },
    },
    beforeDestroy() {
        this.$bus.$off('changePage')
        this.$bus.$off('refreshMemberList')
    },
    beforeRouteLeave(to, from, next) {
        this.openUploadPopup = false
        next()
    },
}
</script>
